import * as React from "react"
import axios from "axios"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Spinner from "react-bootstrap/Spinner"
import {PageProps} from "gatsby"
import {createNodeFromPreview} from "gatsby-drupal-preview"
import Col from "react-bootstrap/Col"

const pageTemplates: Record<string, any> = {
  page: React.lazy(() => import("../../templates/page")),
  homepage: React.lazy(() => import("../index")),
  case_study: React.lazy(() => import("../../templates/caseStudy"))
}

const getTemplate = (bundle: string, data: object) => {
  if (pageTemplates.hasOwnProperty(bundle)) {
    const PreviewTemplate = pageTemplates[bundle]
    return (
      <React.Suspense fallback={<>Loading ${bundle} template...</>}>
        <PreviewTemplate data={data}/>
      </React.Suspense>
    )
  }
  return null;
}

interface GatsbyNode extends Record<string, any> {
  relationships?: Record<string, any>
}

interface PreviewState {
  data?: {
    [nodeName: string]: GatsbyNode
  };
  error: Error | null;
  loaded: boolean;
}

const PreviewPage: React.FC<PageProps> = ({...props}) => {
  const [state, setState] = React.useState<PreviewState>({
    data: undefined,
    error: null,
    loaded: false,
  });

  const { data, error, loaded } = state;

  const splat = props.params[`*`]?.split('/') || []
  const [bundle, uuid, langcode, uid] = splat
  const baseUrl = process.env.GATSBY_BASE_URL || ''

  React.useEffect(() => {
    async function fetchData() {
      try {
        const endpoint = `${process.env.GATSBY_BASE_URL}/api/preview/${uuid}`;
        const response = await axios.get(endpoint, {
          headers: {
            "api-key": process.env.GATSBY_API_KEY || "",
          },
          params: {
            langcode,
            uid,
          },
        });
        setState({ data: createNodeFromPreview(response.data, baseUrl), error: null, loaded: true });
      } catch (e: any) {
        setState({ data: undefined, error: e.message, loaded: true });
      }
    }
    fetchData();
  }, [uuid, langcode]);

  if (error || !loaded) {
    return (
      <Container>
        <Row>
          <Col>
            <>
            {error || (
              <Spinner animation="border">
                <span className="visually-hidden">Loading preview...</span>
              </Spinner>
            )}
            </>
          </Col>
        </Row>
      </Container>
    )
  }
  if (data) {
    return getTemplate(bundle, data)
  }
  return null
}

export default PreviewPage
